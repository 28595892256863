<template>
    <layout-full-width :title="$tc('contact', 2)">
        <component-actions :action-filters="[]"
                           :bulk="bulk"
                           :bulk-actions="bulkActions"
                           :extended-search="extendedSearch"
                           :general-search-fields="generalSearchFields"
                           :items-per-page="this.itemsPerPage"
                           :page="this.page"
                           :primary-actions="primaryActions"
                           :secondary-actions="secondaryActions"
                           :total="this.total"
                           @applyFilters="onApplyFilters"
                           @changeItemsPerPage="onChangeItemsPerPage"
                           @paginate="onChangePage"
                           @refresh="listContacts"/>

        <div class="row">
            <div class="col-12">
                <div class="table-responsive">
                    <table :class="{'table-hover': !loading.CONTACT_LIST}" class="table">
                        <thead>
                        <component-table-header :heading-columns="headingColumns"
                                                :select-all-status="getSelectAllStatus()"
                                                :show-checkbox="true"
                                                @sort="onSortContacts"
                                                @toggleCheckbox="toggleCheckbox"/>
                        </thead>
                        <tbody>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length > 0">
                            <tr v-for="(contact, i) in contacts.list"
                                :key="'contact-' + i"
                                class="pointer"
                                @click="onClickContact($event, contact)">
                                <td>
                                    <button class="btn btn-checkbox" @click="toggleCheckbox(i)">
                                        <font-awesome-icon
                                            :class="contact.selected ? 'text-primary' : ''"
                                            :icon="contact.selected ? ['fas', 'check-square'] : ['far', 'square']"
                                            class="pe-none"/>
                                    </button>
                                </td>
                                <td>{{ contact.address.salutation }}</td>
                                <td>{{ contact.address.firstName }}</td>
                                <td>{{ contact.address.lastName }}</td>
                                <td>{{ contact.address.company }}</td>
                                <td>{{ contact.address.street }}</td>
                                <td>{{ contact.address.postcode }}</td>
                                <td>{{ contact.address.city }}</td>
                                <td>
                                    <template v-if="contact.address.country">
                                        <span>{{ countries[contact.address.country].name }}</span>
                                    </template>
                                </td>
                                <td>{{ contact.contactInformation.email }}</td>
                                <td class="align-middle">
                                    <div class="dropdown">
                                        <button :id="'dropdown-' + i"
                                                aria-expanded="false"
                                                aria-haspopup="true"
                                                class="btn p-0 bg-transparent"
                                                data-bs-toggle="dropdown"
                                                type="button">
                                            <font-awesome-icon class="pe-none" icon="ellipsis-h"/>
                                        </button>
                                        <div :aria-labelledby="'dropdown-' + i"
                                             class="dropdown-menu dropdown-menu-right">
                                            <button class="dropdown-item"
                                                    @click="onDeleteContact(contact.contactUniqueId)">
                                                {{ $t('actions.delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-cloak v-if="!loading.CONTACT_LIST && contacts.list.length === 0">
                            <tr>
                                <td colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('pages.contacts.no-contacts-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-if="loading.CONTACT_LIST">
                            <tr v-for="n in itemsPerPage" :key="'row-shimmer-' + n">
                                <td class="align-middle">
                                    <component-shimmer height="14px" style="margin: 4px 0;" width="14px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="40px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="60px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="60px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="200px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="150px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="60px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="100px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="220px"/>
                                </td>
                                <td class="align-middle">
                                    <component-shimmer width="20px"/>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </div>

            <component-create-contact @create="onCreateContact"/>

            <component-import-contacts
                :active="slideIn.importContact"
                @close="slideIn.importContact = false"
                @import="onImportContacts"/>

            <component-update-contact :active="update.active"
                                      @close="update.active = false"
                                      @update="onUpdateContact"/>

            <div id="modal-add-contact-to-segment"
                 ref="segmentModal"
                 aria-hidden="true"
                 aria-labelledby="dialogNewSegment"
                 class="modal fade"
                 role="dialog"
                 tabindex="-1">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('actions.add-contacts-to-segment') }}</h5>
                            <button :aria-label="$t('actions.close')" class="btn-close" data-bs-dismiss="modal"
                                    type="button"></button>
                        </div>
                        <div v-if="!loading.SEGMENT_ADD_CONTACT" class="modal-body">
                            <form @submit.prevent="addContactsToSegment">
                                <p class="mb-1">{{ $t('select-segment-description') }}</p>
                                <p>
                                    <strong>{{ $tc('selected-contact', 1) }}:</strong>
                                    <span class="badge bg-primary ms-1">{{ selectedContacts().length }}</span>
                                </p>
                                <label class="d-block mb-2" for="new-segment-name">
                                    <strong>{{ $tc('segment', 1) }}</strong>
                                </label>
                                <select id="new-segment-name"
                                        v-model="selectedSegment"
                                        aria-label="Segment select dialog"
                                        class="form-select">
                                    <option disabled value="">{{ $t('actions.please-select') }}</option>
                                    <option v-for="segment in segments.list"
                                            :key="segment.segmentUniqueId"
                                            :value="segment.segmentUniqueId">
                                        {{ segment.segmentDescShort }}
                                    </option>
                                </select>
                                <button :disabled="selectedSegment === ''"
                                        class="btn btn-primary mt-2 float-end"
                                        type="submit">
                                    {{ $t('actions.add-to-segment') }}
                                </button>
                            </form>
                        </div>
                        <div v-if="loading.SEGMENT_ADD_CONTACT"
                             class="modal-body d-flex justify-content-center align-items-center"
                             style="height: 191px;">
                            <component-spinner class="py-4" color="text-primary" type="spinner-grow"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentActions from "@/components/ui/ComponentActions";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import ComponentCreateContact from "@/components/ComponentCreateContact";
import ComponentImportContacts from "@/components/ComponentImportContacts";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentUpdateContact from "@/components/ComponentUpdateContact";
import closeModal from "@/lib/close-modal";
import shouldOpenSlideIn from "@/lib/should-open-slide-in";
import ComponentTableHeader from '@/components/ui/ComponentTableHeader.vue';
import {countries} from "countries-list";

export default {
    name: "PageContacts",
    components: {
        ComponentUpdateContact,
        ComponentShimmer,
        ComponentImportContacts,
        ComponentCreateContact,
        ComponentSpinner,
        ComponentActions,
        LayoutFullWidth,
        ComponentTableHeader
    },
    data() {
        return {
            countries,
            filter: {},
            order: {meta_insert_dt: "DESC"},
            page: 1,
            itemsPerPage: 10,
            total: 0,
            limit: null,
            generalSearchFields: [
                'genContactAddressRels.genAddress.gen_address_first_name',
                'genContactAddressRels.genAddress.gen_address_last_name',
                'genContactAddressRels.genAddress.gen_address_company',
                'genContactAddressRels.genAddress.gen_address_street',
                'genContactAddressRels.genAddress.gen_address_postcode',
                'genContactAddressRels.genAddress.gen_address_city',
                'genContactAddressRels.genAddress.gen_address_country'
            ],
            extendedSearch: [
                {
                    name: this.$tc('first-name', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_first_name'
                },
                {
                    name: this.$tc('last-name', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_last_name'
                },
                {
                    name: this.$tc('company', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_company'
                },
                {
                    name: this.$tc('street', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_street'
                },
                {
                    name: this.$tc('postcode', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_postcode'
                },
                {
                    name: this.$tc('city', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_city'
                },
                {
                    name: this.$tc('country', 1),
                    technicalName: 'genContactAddressRels.genAddress.gen_address_country'
                }
            ],
            primaryActions: [
                {
                    text: this.$t('actions.new'),
                    icon: 'plus',
                    type: 'modal',
                    target: '#modal-add-contact',
                    disabled: false
                }
            ],
            secondaryActions: [
                {
                    text: this.$t('actions.import'),
                    icon: null,
                    type: 'click',
                    click: () => {
                        this.$store.commit('backdrop/show');
                        this.slideIn.importContact = true;
                    },
                    disabled: false
                }
            ],
            bulkActions: [
                {
                    text: this.$t('actions.add-to-segment'),
                    type: 'modal',
                    target: '#modal-add-contact-to-segment',
                    disabled: false
                }
            ],
            bulk: false,
            headingColumns: [
                {
                    title: this.$tc('salutation', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_salutation'
                },
                {
                    title: this.$tc('first-name', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_first_name'
                },
                {
                    title: this.$tc('last-name', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_last_name'
                },
                {
                    title: this.$tc('company', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_company'
                },
                {
                    title: this.$tc('street', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_street'
                },
                {
                    title: this.$tc('postcode', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_postcode'
                },
                {
                    title: this.$tc('city', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_city'
                },
                {
                    title: this.$tc('country', 1),
                    sortField: 'genContactAddressRels.genAddress.gen_address_country'
                },
                {
                    title: this.$tc('email', 1)
                },
                {
                    title: ""
                }
            ],
            update: {
                active: false
            },
            slideIn: {
                importContact: false
            },
            selectedSegment: ''
        }
    },
    computed: {
        loading() {
            return {
                CONTACT_LIST: this.$store.state.loading.CONTACT_LIST,
                SEGMENT_ADD_CONTACT: this.$store.state.loading.SEGMENT_ADD_CONTACT
            }
        },
        contact() {
            return this.$store.state.CONTACT.contact;
        },
        contacts() {
            return this.$store.state.CONTACT.contacts;
        },
        segments() {
            return this.$store.state.SEGMENT.segments;
        }
    },
    methods: {
        listContacts() {
            this.$store.dispatch('CONTACT/LIST', {
                filter: this.filter,
                order: this.order,
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                limit: this.limit,
            })
                .then(contacts => {
                    this.total = contacts.total;
                });
        },
        onUpdateContact() {
            this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.contact-updated')
            });
            this.listSegments();
        },
        onDeleteContact(contactUniqueId) {
            this.$store.commit('loading/CONTACT_LIST', true);
            this.$store.dispatch('CONTACT/DELETE', {contactUniqueId})
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.contact-deleted')
                    });
                    this.listContacts();
                    this.$gtag.event('CONTACT_DELETE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onSortContacts(order) {
            this.order = order;
            this.listContacts();
        },
        listSegments() {
            this.$store.dispatch('SEGMENT/LIST', {
                filter: null,
                order: {meta_insert_dt: "DESC"},
                page: 1,
                itemsPerPage: 25
            });
        },
        async addContactsToSegment() {
            this.$store.dispatch('SEGMENT/ADD_CONTACT', {
                segmentUniqueId: this.selectedSegment,
                contactUniqueId: this.selectedContacts()
            })
                .then(() => {
                    this.$store.dispatch('toast/success', {
                        title: this.$tc('toast.success.success'),
                        message: this.$tc('toast.success.segment-contact-added')
                    });
                    closeModal(this.$refs.segmentModal);
                    this.$gtag.event('SEGMENT_ADD_CONTACT');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onChangePage(page) {
            if (page <= 0) {
                return;
            }

            this.page = parseInt(page, 10);
            this.listContacts();
        },
        onChangeItemsPerPage(itemsPerPage) {
            if (this.itemsPerPage === itemsPerPage) {
                return;
            }

            this.page = 1;
            this.itemsPerPage = itemsPerPage;
            this.listContacts();
        },
        onApplyFilters(filters) {
            this.filter = filters;
            this.listContacts();
        },
        async onCreateContact() {
            await this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.add-contact')
            });
            await this.listContacts();
        },
        async onImportContacts() {
            await this.$store.dispatch('toast/success', {
                title: this.$tc('toast.success.success'),
                message: this.$tc('toast.success.import-contacts')
            });
            await this.listContacts();
        },
        async toggleCheckbox(i) {
            if (i === 'ALL') {
                if (this.getSelectAllStatus()) {
                    this.contacts.list.forEach(contact => contact.selected = false);
                } else {
                    this.contacts.list.forEach(contact => contact.selected = true);
                }
            } else {
                this.contacts.list[i].selected = !this.contacts.list[i].selected;
            }

            this.$forceUpdate();
            this.getActionButton();
        },
        getSelectAllStatus() {
            const contactCount = this.contacts.list.length;
            let selectedContactCount = 0;
            this.contacts.list.forEach(contact => {
                if (contact.selected) {
                    selectedContactCount += 1;
                }
            })
            return contactCount === selectedContactCount && contactCount !== 0;
        },
        getActionButton() {
            let selectedContactCount = 0;
            this.contacts.list.forEach(contact => {
                if (contact.selected) {
                    selectedContactCount += 1;
                }
            })

            this.bulk = selectedContactCount !== 0;
        },
        selectedContacts() {
            let selectedContacts = this.contacts.list.map(contact => {
                if (contact.selected === true) {
                    return contact.contactUniqueId
                }
            })
            return selectedContacts.filter(Boolean);
        },
        onClickContact(event, contact) {
            if (!shouldOpenSlideIn(event.target)) {
                return;
            }

            this.$store.commit('backdrop/show');
            this.update.active = true;
            this.$store.dispatch('CONTACT/GET', contact.contactUniqueId);
        },
    },
    beforeMount() {
        this.listContacts();
        this.listSegments();
    }
}
</script>

<style lang="scss" scoped>
.btn-edit:focus {
    box-shadow: none;
}
</style>
