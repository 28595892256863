import AWS from "aws-sdk";
import getCookie from "./get-cookie";

const user = getCookie('user');

const S3 = new AWS.S3({
    region: process.env.VUE_APP_AWS_REGION,
    credentials: new AWS.Credentials(user.awsAccessKeyId, user.awsSecretAccessKey)
});

export default S3;
