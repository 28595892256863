<template>
    <component-modal id="modal-add-contact"
                     ref="modalCreateContact"
                     :center="true"
                     :title="$t('actions.add-contact')"
                     size="modal-xl">
        <form v-if="loading === false" @submit.prevent="onSubmit">
            <div class="modal-body">
                <div class="row">
                    <!-- Contact information -->
                    <h2 class="h4 mb-3">{{ $tc('address', 1) }}</h2>
                    <!-- Salutation -->
                    <div class="mb-2 col-sm-12 col-xl-4">
                        <label class="form-label" for="salutation">{{ $tc('salutation', 1) }}</label>
                        <input id="salutation"
                               v-model="address.salutation"
                               :class="{'is-invalid': v.address.salutation.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.salutation.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.salutation', {count: v.address.salutation.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- First name -->
                    <div class="mb-2 col-sm-12 col-lg-6 col-xl-4">
                        <label class="form-label" for="first-name">{{ $tc('first-name', 1) }}</label>
                        <input id="first-name"
                               v-model="address.firstName"
                               :class="{'is-invalid': v.address.firstName.$error || errors.firstOrLastName}"
                               class="form-control"
                               type="text">
                        <div v-if="errors.firstOrLastName" class="invalid-feedback">
                            {{ $t('validation.error.required.firstOrLastName') }}
                        </div>
                        <div v-if="!v.address.firstName.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.firstName', {count: v.address.firstName.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Last name -->
                    <div class="mb-2 col-sm-12 col-lg-6 col-xl-4">
                        <label class="form-label" for="last-name">{{ $tc('last-name', 1) }}</label>
                        <input id="last-name"
                               v-model="address.lastName"
                               :class="{'is-invalid': v.address.lastName.$error || errors.firstOrLastName}"
                               class="form-control"
                               type="text">
                        <div v-if="errors.firstOrLastName" class="invalid-feedback">
                            {{ $t('validation.error.required.firstOrLastName') }}
                        </div>
                        <div v-if="!v.address.lastName.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.lastName', {count: v.address.lastName.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Company -->
                    <div class="mb-2 col-sm-12 col-lg-6">
                        <label class="form-label" for="company">{{ $tc('company', 1) }}</label>
                        <input id="company"
                               v-model="address.company"
                               :class="{'is-invalid': v.address.company.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.company.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.company', {count: v.address.company.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- VAT ID -->
                    <div class="mb-2 col-sm-12 col-lg-6">
                        <label class="form-label" for="vat-id">{{ $tc('vat-id', 1) }}</label>
                        <input id="vat-id"
                               v-model="address.vatId"
                               :class="{'is-invalid': v.address.vatId.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.vatId.maxLength" class="invalid-feedback">
                            {{ $t('validation.error.maxLength.vatId', {count: v.address.vatId.$params.maxLength.max}) }}
                        </div>
                    </div>
                    <!-- Street -->
                    <div class="mb-2 col-sm-12 col-xl-6">
                        <label class="form-label" for="street">{{ $tc('street', 1) }}</label>
                        <input id="street"
                               v-model="address.street"
                               :class="{'is-invalid': v.address.street.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.street.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.street', {count: v.address.street.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Postcode -->
                    <div class="mb-2 col-sm-12 col-lg-6 col-xl-3">
                        <label class="form-label" for="postcode">{{ $tc('postcode', 1) }}</label>
                        <input id="postcode"
                               v-model="address.postcode"
                               :class="{'is-invalid': v.address.postcode.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.postcode.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.postcode', {count: v.address.postcode.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- City -->
                    <div class="mb-2 col-sm-12 col-lg-6 col-xl-3">
                        <label class="form-label" for="city">{{ $tc('city', 1) }}</label>
                        <input id="city"
                               v-model="address.city"
                               :class="{'is-invalid': v.address.city.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.city.maxLength" class="invalid-feedback">
                            {{ $t('validation.error.maxLength.city', {count: v.address.city.$params.maxLength.max}) }}
                        </div>
                    </div>
                    <!-- State -->
                    <div class="mb-2 col-sm-12 col-lg-6">
                        <label class="form-label" for="state">{{ $tc('state', 1) }}</label>
                        <input id="state"
                               v-model="address.state"
                               :class="{'is-invalid': v.address.state.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.address.state.maxLength" class="invalid-feedback">
                            {{ $t('validation.error.maxLength.state', {count: v.address.state.$params.maxLength.max}) }}
                        </div>
                    </div>
                    <!-- Country -->
                    <div class="mb-2 col-sm-12 col-lg-6">
                        <label class="form-label" for="country">{{ $tc('country', 1) }}</label>
                        <select id="country"
                                v-model="address.country"
                                :class="{'is-invalid': v.address.country.$error}"
                                class="form-select">
                            <option :value="null">{{ $t('actions.please-select') }}</option>
                            <option v-for="(country, iso2) in countries" :key="'country-' + iso2" :value="iso2">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    <!-- Birthday -->
                    <div class="mb-2 col-sm-12">
                        <label class="form-label" for="birthday">{{ $tc('birthday', 1) }}</label>
                        <input id="birthday"
                               v-model="address.birthday"
                               :class="{'is-invalid': v.address.birthday.$error}"
                               class="form-control"
                               type="date">
                        <div v-if="!v.address.birthday.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.birthday', {count: v.address.birthday.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Contact information -->
                    <h2 class="h4 my-3">{{ $t('contact-information') }}</h2>
                    <!-- Email -->
                    <div class="mb-2 col-sm-12 col-xl-6">
                        <label class="form-label" for="email">{{ $tc('email', 1) }}</label>
                        <input id="email"
                               v-model="contactInformation.email"
                               :class="{'is-invalid': v.contactInformation.email.$error || v.contactInformation.email.$invalid}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.contactInformation.email.email" class="invalid-feedback">
                            {{ $t('validation.error.invalid.email') }}
                        </div>
                        <div v-if="!v.contactInformation.email.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.email', {count: v.contactInformation.email.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Phone (private) -->
                    <div class="mb-2 col-sm-12 col-xl-6">
                        <label class="form-label" for="phone_private">{{ $tc('phone', 1) }} ({{
                                $t('private')
                            }})</label>
                        <input id="phone_private"
                               v-model="contactInformation.phonePrivate"
                               :class="{'is-invalid': v.contactInformation.phonePrivate.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.contactInformation.phonePrivate.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.phonePrivate', {count: v.contactInformation.phonePrivate.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Phone (business) -->
                    <div class="mb-2 col-sm-12 col-xl-6">
                        <label class="form-label" for="phone_business">{{ $tc('phone', 1) }} ({{
                                $tc('business', 1)
                            }})</label>
                        <input id="phone_business"
                               v-model="contactInformation.phoneBusiness"
                               :class="{'is-invalid': v.contactInformation.phoneBusiness.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.contactInformation.phoneBusiness.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.phoneBusiness', {count: v.contactInformation.phoneBusiness.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                    <!-- Phone (mobile) -->
                    <div class="mb-2 col-sm-12 col-xl-6">
                        <label class="form-label" for="phone_mobile">{{ $tc('phone', 1) }} ({{ $t('mobile') }})</label>
                        <input id="phone_mobile"
                               v-model="contactInformation.phoneMobile"
                               :class="{'is-invalid': v.contactInformation.phoneMobile.$error}"
                               class="form-control"
                               type="text">
                        <div v-if="!v.contactInformation.phoneMobile.maxLength" class="invalid-feedback">
                            {{
                                $t('validation.error.maxLength.phoneMobile', {count: v.contactInformation.phoneMobile.$params.maxLength.max})
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="submit">{{ $t('actions.submit') }}</button>
            </div>
        </form>
        <component-spinner v-if="loading === true" class="py-5"/>
    </component-modal>
</template>

<script>
import ComponentModal from "@/components/ui/ComponentModal";
import ComponentSpinner from "@/components/ui/ComponentSpinner";
import closeModal from "@/lib/close-modal";
import {email, maxLength} from 'vuelidate/lib/validators';
import {countries} from "countries-list";

export default {
    name: "ComponentCreateContact",
    components: {ComponentSpinner, ComponentModal},
    computed: {
        v() {
            return this.$v;
        }
    },
    data() {
        return {
            countries: countries,
            modal: null,
            loading: false,
            address: {
                salutation: null,
                firstName: null,
                lastName: null,
                company: null,
                vatId: null,
                street: null,
                postcode: null,
                city: null,
                state: null,
                country: null,
                birthday: null,
            },
            contactInformation: {
                email: null,
                phonePrivate: null,
                phoneBusiness: null,
                phoneMobile: null,
            },
            errors: {
                firstOrLastName: false
            }
        }
    },
    methods: {
        async onSubmit() {
            this.loading = true;
            this.errors.firstOrLastName = false;
            this.$v.$touch();

            // Replace empty strings with null
            Object.keys(this.address).forEach(i => this.address[i] = this.address[i] === '' ? null : this.address[i]);
            Object.keys(this.contactInformation).forEach(j => this.contactInformation[j] = this.contactInformation[j] === '' ? null : this.contactInformation[j])

            if (!this.address.firstName && !this.address.firstName) {
                this.errors.firstOrLastName = true;
                this.loading = false;
                return;
            }

            if (!this.$v.$invalid) {
                this.$store.dispatch('CONTACT/CREATE', {
                    address: this.address,
                    contactInformation: this.contactInformation
                })
                    .then(contact => {
                        closeModal(this.$refs.modalCreateContact.$el);
                        this.reset();
                        this.loading = false;
                        this.$emit('create', contact);
                        this.$gtag.event('CONTACT_CREATE');
                    })
                    .catch(error => {
                        this.$store.dispatch('toast/danger', {
                            title: this.$tc('toast.danger.error'),
                            message: error.response.data.message
                        });
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        },
        reset() {
            this.address = {
                salutation: null,
                firstName: null,
                lastName: null,
                company: null,
                vatId: null,
                street: null,
                postcode: null,
                city: null,
                state: null,
                country: null,
                birthday: null,
            }
            this.contactInformation = {
                email: null,
                phonePrivate: null,
                phoneBusiness: null,
                phoneMobile: null,
            }
            this.$v.$reset();
        }
    },
    validations: {
        address: {
            salutation: {
                maxLength: maxLength(255)
            },
            firstName: {
                maxLength: maxLength(255)
            },
            lastName: {
                maxLength: maxLength(255)
            },
            company: {
                maxLength: maxLength(255)
            },
            vatId: {
                maxLength: maxLength(50)
            },
            street: {
                maxLength: maxLength(255)
            },
            postcode: {
                maxLength: maxLength(255)
            },
            city: {
                maxLength: maxLength(255)
            },
            state: {
                maxLength: maxLength(50)
            },
            country: {
                maxLength: maxLength(2)
            },
            birthday: {
                maxLength: maxLength(10)
            }
        },
        contactInformation: {
            email: {
                email,
                maxLength: maxLength(255)
            },
            phonePrivate: {
                maxLength: maxLength(255)
            },
            phoneBusiness: {
                maxLength: maxLength(255)
            },
            phoneMobile: {
                maxLength: maxLength(255)
            }
        }
    }
}
</script>

<style scoped>

</style>
