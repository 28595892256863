<template>
    <component-slide-in :class="{ active }" @close="close">

        <template v-if="!loading.CONTACT_UPDATE && Object.keys(contact).length > 0">
            <div class="d-flex align-items-center mb-3">
                <component-avatar>{{ contactInitials }}</component-avatar>
                <component-slide-in-title :editable="false"
                                          :title="contactTitle"
                                          :uuid="contact.contactUniqueId"
                                          class="ms-3 flex-grow-1"/>
            </div>

            <nav class="nav nav-tabs mb-3">
                <button id="nav-general-tab"
                        aria-controls="nav-general"
                        aria-selected="true"
                        class="nav-link active"
                        data-bs-target="#nav-general"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('general') }}
                </button>
                <button id="nav-contact-information-tab"
                        aria-controls="nav-contact-information"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-contact-information"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $t('contact-information') }}
                </button>
                <button id="nav-references-tab"
                        aria-controls="nav-references"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-references"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('reference', 2) }}
                </button>
                <button id="nav-comments-tab"
                        aria-controls="nav-comments"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-comments"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('comment', 2) }}
                </button>
                <button id="nav-activity-tab"
                        aria-controls="nav-activity"
                        aria-selected="false"
                        class="nav-link"
                        data-bs-target="#nav-activity"
                        data-bs-toggle="tab"
                        role="tab"
                        type="button">{{ $tc('activity', 1) }}
                </button>
            </nav>

            <div id="nav-tabContent" class="tab-content">
                <div id="nav-general" aria-labelledby="nav-general-tab" class="tab-pane fade show active"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateContact">
                        <div class="row">
                            <div class="col-6">
                                <p><strong>{{ $t('personal-information') }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-salutation">{{ $tc('salutation', 1) }}</label>
                                    <input id="contact-salutation"
                                           v-model="personalInformationSalutation"
                                           :aria-label="$tc('salutation', 1)"
                                           :disabled="!edit.general.personalInformation.flag"
                                           aria-describedby="salutation"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-first-name">{{ $tc('first-name', 1) }}</label>
                                    <input id="contact-first-name"
                                           v-model="personalInformationFirstName"
                                           :aria-label="$tc('first-name', 1)"
                                           :disabled="!edit.general.personalInformation.flag"
                                           aria-describedby="first-name"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-last-name">{{ $tc('last-name', 1) }}</label>
                                    <input id="contact-last-name"
                                           v-model="personalInformationLastName"
                                           :aria-label="$tc('last-name', 1)"
                                           :disabled="!edit.general.personalInformation.flag"
                                           aria-describedby="last-name"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="contact-birthday">{{ $tc('birthday', 1) }}</label>
                                    <input id="contact-birthday"
                                           v-model="personalInformationBirthday"
                                           :aria-label="$tc('birthday', 1)"
                                           :disabled="!edit.general.personalInformation.flag"
                                           aria-describedby="birthday"
                                           class="form-control form-control-sm"
                                           type="date">
                                </div>

                                <p>
                                    <button v-if="!edit.general.personalInformation.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEditPersonalInformation">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.general.personalInformation.flag"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.general.personalInformation.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.general.personalInformation.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>

                            <div class="col-6">
                                <p><strong>{{ $tc('company', 1) }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-company">{{ $tc('company', 1) }}</label>
                                    <input id="contact-company"
                                           v-model="companyCompany"
                                           :aria-label="$tc('company', 1)"
                                           :disabled="!edit.general.company.flag"
                                           aria-describedby="company"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="contact-vat-id">{{ $t('vat-id') }}</label>
                                    <input id="contact-vat-id"
                                           v-model="companyVatId"
                                           :aria-label="$t('vat-id')"
                                           :disabled="!edit.general.company.flag"
                                           aria-describedby="vat-id"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>

                                <p>
                                    <button v-if="!edit.general.company.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            type="button"
                                            @click="onClickEditCompany">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.general.company.flag"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.general.company.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.general.company.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-contact-information" aria-labelledby="nav-contact-information-tab" class="tab-pane fade"
                     role="tabpanel">
                    <form @submit.prevent="onUpdateContact">
                        <div class="row">
                            <div class="col-6">
                                <p><strong>{{ $tc('physical-address', 1) }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-street">{{ $tc('street', 1) }}</label>
                                    <input id="contact-street"
                                           v-model="physicalAddressStreet"
                                           :aria-label="$tc('street', 1)"
                                           :disabled="!edit.contactInformation.physicalAddress.flag"
                                           aria-describedby="street"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-postcode">{{ $tc('postcode', 1) }}</label>
                                    <input id="contact-postcode"
                                           v-model="physicalAddressPostcode"
                                           :aria-label="$tc('postcode', 1)"
                                           :disabled="!edit.contactInformation.physicalAddress.flag"
                                           aria-describedby="postcode"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-city">{{ $tc('city', 1) }}</label>
                                    <input id="contact-city"
                                           v-model="physicalAddressCity"
                                           :aria-label="$tc('city', 1)"
                                           :disabled="!edit.contactInformation.physicalAddress.flag"
                                           aria-describedby="city"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-state">{{ $tc('state', 1) }}</label>
                                    <input id="contact-state"
                                           v-model="physicalAddressState"
                                           :aria-label="$tc('state', 1)"
                                           :disabled="!edit.contactInformation.physicalAddress.flag"
                                           aria-describedby="state"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="contact-country">{{ $tc('country', 1) }}</label>
                                    <select id="contact-country"
                                            v-model="physicalAddressCountry"
                                            :disabled="!edit.contactInformation.physicalAddress.flag"
                                            class="form-select form-select-sm">
                                        <option disabled value="">{{ $t('actions.please-select') }}</option>
                                        <option v-for="(country, iso2) in countries"
                                                :key="'country-' + iso2"
                                                :value="iso2">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>

                                <p>
                                    <button v-if="!edit.contactInformation.physicalAddress.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            @click="onClickEditPhysicalAddress">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.contactInformation.physicalAddress.flag"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.contactInformation.physicalAddress.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.contactInformation.physicalAddress.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>

                            </div>
                            <div class="col-6">
                                <p><strong>{{ $t('contact-information') }}</strong></p>

                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-email">{{ $tc('email', 1) }}</label>
                                    <input id="contact-email"
                                           v-model="digitalInformationEmail"
                                           :aria-label="$tc('email', 1)"
                                           :disabled="!edit.contactInformation.digitalContactInformation.flag"
                                           aria-describedby="email"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-phone-private">{{ $t('phone-private') }}</label>
                                    <input id="contact-phone-private"
                                           v-model="digitalInformationPhonePrivate"
                                           :aria-label="$t('phone-private')"
                                           :disabled="!edit.contactInformation.digitalContactInformation.flag"
                                           aria-describedby="phone-private"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-1">
                                    <label class="form-label small"
                                           for="contact-phone-business">{{ $t('phone-business') }}</label>
                                    <input id="contact-phone-business"
                                           v-model="digitalInformationPhoneBusiness"
                                           :aria-label="$t('phone-business')"
                                           :disabled="!edit.contactInformation.digitalContactInformation.flag"
                                           aria-describedby="phone-business"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label small"
                                           for="contact-phone-mobile">{{ $t('phone-mobile') }}</label>
                                    <input id="contact-phone-mobile"
                                           v-model="digitalInformationPhoneMobile"
                                           :aria-label="$t('phone-mobile')"
                                           :disabled="!edit.contactInformation.digitalContactInformation.flag"
                                           aria-describedby="phone-mobile"
                                           class="form-control form-control-sm"
                                           type="text">
                                </div>

                                <p>
                                    <button v-if="!edit.contactInformation.digitalContactInformation.flag"
                                            class="btn btn-sm btn-outline-primary me-2"
                                            @click="onClickEditContactInformation">
                                        {{ $t('actions.edit') }}
                                    </button>
                                    <button v-if="edit.contactInformation.digitalContactInformation.flag"
                                            class="btn btn-sm btn-outline-success me-2">
                                        {{ $t('actions.update') }}
                                    </button>
                                    <button v-if="edit.contactInformation.digitalContactInformation.flag"
                                            class="btn btn-sm btn-outline-danger"
                                            type="button"
                                            @click="edit.contactInformation.digitalContactInformation.flag = false">
                                        {{ $t('actions.cancel') }}
                                    </button>
                                </p>

                            </div>
                        </div>
                    </form>
                </div>
                <div id="nav-references" aria-labelledby="nav-references-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-references-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-comments" aria-labelledby="nav-comments-tab" class="tab-pane fade" role="tabpanel">
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                            <tr>
                                <td class="border-bottom-0" colspan="99">
                                    <div
                                        class="py-3 text-primary d-flex align-items-center justify-content-center flex-column mb-0"
                                        role="alert">
                                        <font-awesome-icon icon="exclamation-triangle" size="3x"/>
                                        <div class="mt-2 fs-4">{{ $t('no-comments-found') }}</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="nav-activity" aria-labelledby="nav-activity-tab" class="tab-pane fade" role="tabpanel">
                    <div class="list-group">
                        <div v-if="contact.meta_update_dt" class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.contact-updated') }}</p>
                                <small>{{ contact.meta_update_dt | timestamp }}</small>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <p class="mb-0">{{ $t('activity-logs.contact-created') }}</p>
                                <small>{{ contact.meta_insert_dt | timestamp }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </template>

        <div v-else>
            <div class="d-flex align-items-center mb-3">
                <component-shimmer class="rounded-circle d-block me-3" height="64px" width="64px"/>
                <div>
                    <component-shimmer class="mb-1"/>
                    <component-shimmer/>
                </div>
            </div>

            <div class="d-flex mb-3">
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
                <component-shimmer class="me-2" height="37.2px" width="25%"/>
            </div>

            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>
            <component-shimmer class="mb-3" height="26.8px" width="100%"/>

            <component-shimmer height="33.2px" width="70px"/>
        </div>

    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import ComponentShimmer from "@/components/ComponentShimmer";
import ComponentAvatar from "@/components/ui/ComponentAvatar";
import ComponentSlideInTitle from "@/components/ui/ComponentSlideInTitle";
import {countries} from "countries-list";
import moment from "moment";

export default {
    name: "ComponentUpdateContact",
    components: {
        ComponentSlideInTitle,
        ComponentAvatar,
        ComponentShimmer,
        ComponentSlideIn
    },
    computed: {
        loading() {
            return {
                CONTACT_UPDATE: this.$store.state.loading.CONTACT_UPDATE
            }
        },
        contact() {
            return this.$store.state.CONTACT.contact;
        },
        contactInitials() {
            const contactAddress = this.contact.address;

            if (contactAddress.firstName && contactAddress.lastName) {
                return contactAddress.firstName.substr(0, 1).toUpperCase() + contactAddress.lastName.substr(0, 1).toUpperCase();
            } else if (contactAddress.firstName && !contactAddress.lastName) {
                return contactAddress.firstName.substr(0, 1).toUpperCase();
            } else if (!contactAddress.firstName && contactAddress.lastName) {
                return contactAddress.lastName.substr(0, 1).toUpperCase();
            } else if (contactAddress.company) {
                return contactAddress.company.substr(0, 1).toUpperCase();
            }

            return '';
        },
        contactTitle() {
            const contactAddress = this.contact.address;

            if (contactAddress.firstName && contactAddress.lastName) {
                return contactAddress.firstName + ' ' + contactAddress.lastName;
            } else if (contactAddress.firstName && !contactAddress.lastName) {
                return contactAddress.firstName;
            } else if (!contactAddress.firstName && contactAddress.lastName) {
                return contactAddress.lastName;
            } else if (contactAddress.company) {
                return contactAddress.company;
            }

            return '';
        },
        personalInformationSalutation: {
            get() {
                return this.edit.general.personalInformation.flag ? this.edit.general.personalInformation.salutation : this.contact.address.salutation;
            },
            set(personalInformationSalutation) {
                this.edit.general.personalInformation.salutation = personalInformationSalutation;
            }
        },
        personalInformationFirstName: {
            get() {
                return this.edit.general.personalInformation.flag ? this.edit.general.personalInformation.firstName : this.contact.address.firstName;
            },
            set(personalInformationFirstName) {
                this.edit.general.personalInformation.firstName = personalInformationFirstName;
            }
        },
        personalInformationLastName: {
            get() {
                return this.edit.general.personalInformation.flag ? this.edit.general.personalInformation.lastName : this.contact.address.lastName;
            },
            set(personalInformationLastName) {
                this.edit.general.personalInformation.lastName = personalInformationLastName;
            }
        },
        personalInformationBirthday: {
            get() {
                if (this.edit.general.personalInformation.flag) {
                    if (this.edit.general.personalInformation.birthday) {
                        return this.edit.general.personalInformation.birthday
                    }

                    return moment(this.edit.general.personalInformation.birthday).format('YYYY-MM-DD');
                }

                if (this.contact.address.birthday) {
                    return moment(this.contact.address.birthday).format('YYYY-MM-DD');
                }

                return this.contact.address.birthday;
            },
            set(personalInformationBirthday) {
                this.edit.general.personalInformation.birthday = personalInformationBirthday;
            }
        },
        companyCompany: {
            get() {
                return this.edit.general.company.flag ? this.edit.general.company.company : this.contact.address.company;
            },
            set(companyCompany) {
                this.edit.general.company.company = companyCompany;
            }
        },
        companyVatId: {
            get() {
                return this.edit.general.company.flag ? this.edit.general.company.vatId : this.contact.address.vatId;
            },
            set(companyVatId) {
                this.edit.general.company.vatId = companyVatId;
            }
        },
        physicalAddressStreet: {
            get() {
                return this.edit.contactInformation.physicalAddress.flag ? this.edit.contactInformation.physicalAddress.street : this.contact.address.street;
            },
            set(physicalAddressStreet) {
                this.edit.contactInformation.physicalAddress.street = physicalAddressStreet;
            }
        },
        physicalAddressPostcode: {
            get() {
                return this.edit.contactInformation.physicalAddress.flag ? this.edit.contactInformation.physicalAddress.postcode : this.contact.address.postcode;
            },
            set(physicalAddressPostcode) {
                this.edit.contactInformation.physicalAddress.postcode = physicalAddressPostcode;
            }
        },
        physicalAddressCity: {
            get() {
                return this.edit.contactInformation.physicalAddress.flag ? this.edit.contactInformation.physicalAddress.city : this.contact.address.city;
            },
            set(physicalAddressCity) {
                this.edit.contactInformation.physicalAddress.city = physicalAddressCity;
            }
        },
        physicalAddressState: {
            get() {
                return this.edit.contactInformation.physicalAddress.flag ? this.edit.contactInformation.physicalAddress.state : this.contact.address.state;
            },
            set(physicalAddressState) {
                this.edit.contactInformation.physicalAddress.state = physicalAddressState;
            }
        },
        physicalAddressCountry: {
            get() {
                return this.edit.contactInformation.physicalAddress.flag ? this.edit.contactInformation.physicalAddress.country : this.contact.address.country;
            },
            set(physicalAddressCountry) {
                this.edit.contactInformation.physicalAddress.country = physicalAddressCountry;
            }
        },
        digitalInformationEmail: {
            get() {
                return this.edit.contactInformation.digitalContactInformation.flag ? this.edit.contactInformation.digitalContactInformation.email : this.contact.contactInformation.email;
            },
            set(digitalInformationEmail) {
                this.edit.contactInformation.digitalContactInformation.email = digitalInformationEmail;
            }
        },
        digitalInformationPhonePrivate: {
            get() {
                return this.edit.contactInformation.digitalContactInformation.flag ? this.edit.contactInformation.digitalContactInformation.phonePrivate : this.contact.contactInformation.phonePrivate;
            },
            set(digitalInformationPhonePrivate) {
                this.edit.contactInformation.digitalContactInformation.phonePrivate = digitalInformationPhonePrivate;
            }
        },
        digitalInformationPhoneBusiness: {
            get() {
                return this.edit.contactInformation.digitalContactInformation.flag ? this.edit.contactInformation.digitalContactInformation.phoneBusiness : this.contact.contactInformation.phoneBusiness;
            },
            set(digitalInformationPhoneBusiness) {
                this.edit.contactInformation.digitalContactInformation.phoneBusiness = digitalInformationPhoneBusiness;
            }
        },
        digitalInformationPhoneMobile: {
            get() {
                return this.edit.contactInformation.digitalContactInformation.flag ? this.edit.contactInformation.digitalContactInformation.phoneMobile : this.contact.contactInformation.phoneMobile;
            },
            set(digitalInformationPhoneMobile) {
                this.edit.contactInformation.digitalContactInformation.phoneMobile = digitalInformationPhoneMobile;
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            moment,
            countries,
            edit: {
                general: {
                    personalInformation: {
                        flag: false,
                        salutation: '',
                        firstName: '',
                        lastName: '',
                        birthday: ''
                    },
                    company: {
                        flag: false,
                        company: '',
                        vatId: ''
                    }
                },
                contactInformation: {
                    physicalAddress: {
                        flag: false,
                        street: '',
                        postcode: '',
                        city: '',
                        state: '',
                        country: ''
                    },
                    digitalContactInformation: {
                        flag: false,
                        email: '',
                        phonePrivate: '',
                        phoneBusiness: '',
                        phoneMobile: ''
                    }
                }
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onUpdateContact() {
            const payload = {
                contactUniqueId: this.contact.contactUniqueId,
                address: {
                    salutation: this.personalInformationSalutation !== '' ? this.personalInformationSalutation : null,
                    firstName: this.personalInformationFirstName !== '' ? this.personalInformationFirstName : null,
                    lastName: this.personalInformationLastName !== '' ? this.personalInformationLastName : null,
                    company: this.companyCompany !== '' ? this.companyCompany : null,
                    vatId: this.companyVatId !== '' ? this.companyVatId : null,
                    street: this.physicalAddressStreet !== '' ? this.physicalAddressStreet : null,
                    postcode: this.physicalAddressPostcode !== '' ? this.physicalAddressPostcode : null,
                    city: this.physicalAddressCity !== '' ? this.physicalAddressCity : null,
                    state: this.physicalAddressState !== '' ? this.physicalAddressState : null,
                    country: this.physicalAddressCountry !== '' ? this.physicalAddressCountry : null,
                    birthday: this.personalInformationBirthday !== '' ? this.personalInformationBirthday : null
                },
                contactInformation: {
                    email: this.digitalInformationEmail !== '' ? this.digitalInformationEmail : null,
                    phonePrivate: this.digitalInformationPhonePrivate !== '' ? this.digitalInformationPhonePrivate : null,
                    phoneBusiness: this.digitalInformationPhoneBusiness !== '' ? this.digitalInformationPhoneBusiness : null,
                    phoneMobile: this.digitalInformationPhoneMobile !== '' ? this.digitalInformationPhoneMobile : null,
                }
            }

            this.$store.dispatch('CONTACT/UPDATE', payload)
                .then(() => {
                    this.edit.general.personalInformation.flag = false;
                    this.edit.general.company.flag = false;
                    this.edit.contactInformation.physicalAddress.flag = false;
                    this.edit.contactInformation.digitalContactInformation.flag = false;
                    this.$emit('update', this.contact);
                    this.$gtag.event('CONTACT_UPDATE');
                })
                .catch(error => {
                    this.$store.dispatch('toast/danger', {
                        title: this.$tc('toast.danger.error'),
                        message: error.response.data.message
                    });
                });
        },
        onClickEditPersonalInformation() {
            this.edit.general.personalInformation.salutation = this.contact.address.salutation;
            this.edit.general.personalInformation.firstName = this.contact.address.firstName;
            this.edit.general.personalInformation.lastName = this.contact.address.lastName;

            if (this.contact.address.birthday) {
                this.edit.general.personalInformation.birthday = moment(this.contact.address.birthday).format('YYYY-MM-DD');
            } else {
                this.edit.general.personalInformation.birthday = this.contact.address.birthday;
            }

            this.edit.general.personalInformation.flag = true;
        },
        onClickEditCompany() {
            this.edit.general.company.company = this.contact.address.company;
            this.edit.general.company.vatId = this.contact.address.vatId;
            this.edit.general.company.flag = true;
        },
        onClickEditPhysicalAddress() {
            this.edit.contactInformation.physicalAddress.street = this.contact.address.street;
            this.edit.contactInformation.physicalAddress.postcode = this.contact.address.postcode;
            this.edit.contactInformation.physicalAddress.city = this.contact.address.city;
            this.edit.contactInformation.physicalAddress.state = this.contact.address.state;
            this.edit.contactInformation.physicalAddress.country = this.contact.address.country;
            this.edit.contactInformation.physicalAddress.flag = true;
        },
        onClickEditContactInformation() {
            this.edit.contactInformation.digitalContactInformation.email = this.contact.contactInformation.email;
            this.edit.contactInformation.digitalContactInformation.phonePrivate = this.contact.contactInformation.phonePrivate;
            this.edit.contactInformation.digitalContactInformation.phoneBusiness = this.contact.contactInformation.phoneBusiness;
            this.edit.contactInformation.digitalContactInformation.phoneMobile = this.contact.contactInformation.phoneMobile;
            this.edit.contactInformation.digitalContactInformation.flag = true;
        },
        closeOnEscape(e) {
            if (e.key === 'Escape' && this.active === true) {
                this.close();
            }
        }
    },
    watch: {
        active(newValue) {
            if (newValue === true) {
                window.addEventListener('keydown', this.closeOnEscape);
            } else {
                window.removeEventListener('keydown', this.closeOnEscape);
            }
        }
    }
}
</script>

<style scoped>

</style>
