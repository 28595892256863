<template>
    <tr>
        <th v-if="showCheckbox" scope="col" style="width: 2em">
            <button class="btn btn-checkbox" @click="toggleCheckbox">
                <font-awesome-icon :class="selectAllStatus ? 'text-primary' : ''"
                                   :icon="selectAllStatus ? ['fas', 'check-square'] : ['far', 'square']"/>
            </button>
        </th>
        <th v-for="(headingColumn, i) in headingColumns" :key="'heading-column-' + i"
            :class="headingColumn.sortField ? 'heading-column' : ''"
            scope="col"
            @click="sort(headingColumn.sortField)">
            <span class="me-2">{{ headingColumn.title }}</span>
            <font-awesome-icon v-if="headingColumn.sortField"
                               :class="sortIconClass(headingColumn.sortField)"
                               :icon="sortIcon(headingColumn.sortField)"/>
        </th>
    </tr>
</template>

<script>

export default {
    name: "ComponentTableHeader",
    props: {
        headingColumns: {
            type: Array,
            required: true
        },
        selectAllStatus: {
            type: Boolean,
            required: false
        },
        showCheckbox: {
            type: Boolean,
            required: false
        },
        defaultSorting: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            field: null,
            direction: null,
            clicks: 0
        }
    },
    methods: {
        toggleCheckbox() {
            this.$emit('toggleCheckbox', 'ALL');
        },
        sort(field) {
            if (!field) {
                return;
            }

            // set field, direction and number of clicks
            if (field === this.field) {
                this.clicks += 1;
            }

            this.field = field;

            switch (this.direction) {
                case null:
                    this.direction = 'ASC';
                    break;
                case 'ASC':
                    this.direction = 'DESC'
                    break;
                case 'DESC':
                    this.direction = 'ASC'
                    break;
            }

            // reset on third click
            if (this.clicks === 2) {
                this.field = null;
                this.direction = null;
                this.clicks = 0;
            }

            // construct sort object
            if (this.direction === null || this.field === null) {
                this.defaultSorting ? this.$emit('sort', this.defaultSorting) : this.$emit('sort', {meta_insert_dt: "DESC"});
                return;
            }

            this.$emit('sort', {[this.field]: this.direction});
        },
        sortIcon(field) {
            if (this.field === field) {
                if (this.direction === 'ASC') {
                    return 'sort-down'
                }

                if (this.direction === 'DESC') {
                    return 'sort-up'
                }
            }

            return 'sort';
        },
        sortIconClass(field) {
            if (this.field === field && this.direction) {
                return 'sort-icon-active'
            }

            return 'sort-icon-inactive';
        }
    },
}
</script>
<style lang="scss" scoped>
.heading-column {
    cursor: pointer;

    &:hover .sort-icon-inactive {
        opacity: 1;
    }
}

.sort-icon-active {
    opacity: 1;
}

.sort-icon-inactive {
    opacity: .3;
}
</style>


