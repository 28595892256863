<template>
    <component-slide-in :class="{ active }" size="lg" @close="close">
        <form ref="form" class="position-relative mt-4" @submit.prevent="onSubmit">
            <input ref="file" accept="text/csv" class="d-none" type="file" @change="onParseCsv">

            <template v-if="step === 1">
                <!-- Select type of import -->
                <div class="list-group">
                    <div class="list-group-item list-group-item-action d-flex p-3 px-4"
                         role="button"
                         @click="$refs.file.click()">
                        <font-awesome-icon class="me-4 text-primary" icon="file-csv" size="3x"/>
                        <div>
                            <h5 class="mb-1">{{ $t('csv-import') }}</h5>
                            <small class="text-muted">{{ $t('csv-import-desc') }}</small>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="step === 2">

                <div class="mb-4">
                    <button class="btn btn-secondary me-2" type="button" @click="step = 1">
                        <font-awesome-icon class="me-1" icon="arrow-left"/>
                        {{ $t('actions.back') }}
                    </button>
                    <button class="btn btn-primary">
                        <font-awesome-icon class="me-1" icon="file-import"/>
                        {{ $t('actions.import') }}
                    </button>
                </div>

                <h5><strong>{{ $t('variable-mapping') }}</strong></h5>
                <p class="mb-3">{{ $t('slide-ins.import-contacts.variable-mapping') }}</p>

                <div class="table-responsive mb-4">
                    <table class="table table-sm table-hover mb-0">
                        <thead>
                        <tr>
                            <th scope="col">
                                <strong class="flex-basis-33">{{ $tc('variable-name', 1) }}</strong>
                            </th>
                            <th scope="col">
                                <strong class="flex-basis-33">{{ $tc('csv-variable', 1) }}</strong>
                            </th>
                        </tr>
                        <tr v-for="(heading, i) in csv.headers" :key="'variable-' + i">
                            <th class="align-middle"><strong>{{ heading }}</strong></th>
                            <td>
                                <label :for="'csv-variable-' + i" class="form-label sr-only">
                                    <strong>{{ $tc('csv-variable', 1) }}</strong>
                                </label>
                                <select :id="'csv-variable-' + i"
                                        v-model="csv.columnMapping[heading]"
                                        :class="{ 'is-invalid': csv.columnMapping[heading] === '' }"
                                        class="form-select form-select-sm w-100"
                                        required="required"
                                        @change="$forceUpdate()">
                                    <option value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                                    <option :value="null">{{ $t('actions.do-not-import') }}</option>
                                    <optgroup v-for="(contactField, i) in contactFields"
                                              :key="'contact-field-optgroup-' + i"
                                              :label="contactField.label">
                                        <option v-for="(label, value) in contactField.options"
                                                :key="'contact-field-option-' + i + '-' + label"
                                                :disabled="isContactFieldDisabled(value)"
                                                :value="value">{{ label }}
                                        </option>
                                    </optgroup>
                                </select>
                            </td>
                        </tr>
                        </thead>
                    </table>
                </div>

                <!-- CSV Import - Preview data -->
                <ul v-if="csv.errors.length > 0" class="list-group mb-3">
                    <li class="list-group-item list-group-item-warning">
                        <strong class="m-0">{{
                                $tc('error-found', csv.errors.length, {errors: csv.errors.length})
                            }}</strong>
                    </li>
                    <li v-for="(error, i) in csv.errors"
                        :key="'csv-upload-error-' + i"
                        class="list-group-item list-group-item-warning d-flex justify-content-between align-items-start">
                        <div class="me-auto">
                            <div class="fw-bold">{{ error.message }}</div>
                            {{ error.type }}, {{ error.code }}
                        </div>
                        <span class="badge bg-warning rounded-pill">{{ $tc('row', 1) }} {{ error.row }}</span>
                    </li>
                </ul>

                <h5><strong>{{ $tc('preview', 1) }}</strong></h5>
                <p>{{ $t('slide-ins.import-contacts.subtitle') }}</p>

                <div class="btn-group w-100 mb-3" role="group">
                    <div class="input-group input-group-sm me-2">
                        <span class="input-group-text">{{ $tc('encoding', 1) }}</span>
                        <select v-model="csv.encoding"
                                :class="{ 'is-invalid': !csv.encoding }"
                                class="form-select"
                                required="required"
                                @change="onParseCsv">
                            <option value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                            <option v-for="(encoding, i) in encodings"
                                    :key="'encoding-' + i"
                                    :value="encoding.value">{{ encoding.label }}
                            </option>
                        </select>
                    </div>
                    <div class="input-group input-group-sm me-2">
                        <span class="input-group-text">{{ $tc('delimiter', 1) }}</span>
                        <select v-model="csv.delimiter"
                                :class="{ 'is-invalid': !csv.delimiter }"
                                class="form-select"
                                required="required"
                                @change="onParseCsv">
                            <option value=";">;</option>
                            <option value=",">,</option>
                        </select>
                    </div>
                    <div class="input-group input-group-sm me-2">
                        <span class="input-group-text">{{ $tc('preview', 1) }}</span>
                        <select v-model="csv.preview"
                                class="form-select"
                                @change="onParseCsv">
                            <option value="">&mdash; {{ $t('actions.please-select') }} &mdash;</option>
                            <option :value="20">20</option>
                            <option :value="100">100</option>
                            <option :value="200">200</option>
                        </select>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                        <tr>
                            <th v-for="(heading, i) in csv.headers" :key="'csv-data-heading-' + i" class="bg-light"
                                scope="col">
                                <strong>{{ heading }}</strong>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(data, i) in csv.data" :key="'csv-data-body-' + i">
                            <td v-for="(value, key) in data" :key="'csv-data-body-cell-' + key">
                                {{ data[key] }}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="alert alert-info d-flex align-items-center">
                    <font-awesome-icon class="me-3" icon="info-circle" size="2x"/>
                    {{ $t('alerts.info.csv-size-limit') }}
                </div>

                <!-- LOADING PROGRESS BAR -->
                <div v-if="upload.active" class="upload-overlay d-flex p-3 position-absolute align-items-center">
                    <!-- Upload progress -->
                    <div class="progress">
                        <div :aria-valuemax="100"
                             :aria-valuemin="0"
                             :aria-valuenow="upload.progress"
                             :style="{width: upload.progress + '%'}"
                             class="progress-bar progress-bar-striped progress-bar-animated"
                             role="progressbar"></div>
                    </div>
                </div>
            </template>
        </form>
    </component-slide-in>
</template>

<script>
import ComponentSlideIn from "@/components/ui/ComponentSlideIn";
import {countries} from 'countries-list';
import S3 from "@/lib/s3";
import AWS from "aws-sdk";
import {v4 as v4uuid} from "uuid";
import moment from "moment";

export default {
    name: "ComponentImportContacts",
    components: {ComponentSlideIn},
    computed: {
        v() {
            return this.$v;
        },
        partyUniqueId() {
            return this.$store.state.USER.user.partyUniqueId;
        },
        encodings() {
            // ISO-8859-1
            return [
                {
                    value: 'ibm866',
                    label: 'IBM866',
                },
                {
                    value: 'iso-8859-2',
                    label: 'ISO-8859-2'
                },
                {
                    value: 'iso-8859-3',
                    label: 'ISO-8859-3'
                },
                {
                    value: 'iso-8859-4',
                    label: 'ISO-8859-4'
                },
                {
                    value: 'iso-8859-5',
                    label: 'ISO-8859-5'
                },
                {
                    value: 'iso-8859-6',
                    label: 'ISO-8859-6'
                },
                {
                    value: 'iso-8859-7',
                    label: 'ISO-8859-7'
                },
                {
                    value: 'iso-8859-8',
                    label: 'ISO-8859-8'
                },
                {
                    value: 'iso-8859-8-i',
                    label: 'ISO-8859-8-I'
                },
                {
                    value: 'iso-8859-10',
                    label: 'ISO-8859-10'
                },
                {
                    value: 'iso-8859-13',
                    label: 'ISO-8859-13'
                },
                {
                    value: 'iso-8859-14',
                    label: 'ISO-8859-14'
                },
                {
                    value: 'iso-8859-15',
                    label: 'ISO-8859-15'
                },
                {
                    value: 'iso-8859-16',
                    label: 'ISO-8859-16'
                },
                {
                    value: 'koi8-r',
                    label: 'KOI8-R'
                },
                {
                    value: 'macintosh',
                    label: 'Macintosh'
                },
                {
                    value: 'iso8859-11',
                    label: 'Windows 874'
                },
                {
                    value: 'windows-1250',
                    label: 'Windows 1250'
                },
                {
                    value: 'windows-1251',
                    label: 'Windows 1251'
                },
                {
                    value: 'iso-8859-1',
                    label: 'Windows 1252'
                },
                {
                    value: 'windows-1253',
                    label: 'Windows 1253'
                },
                {
                    value: 'windows-1254',
                    label: 'Windows 1254'
                },
                {
                    value: 'windows-1255',
                    label: 'Windows 1255'
                },
                {
                    value: 'windows-1256',
                    label: 'Windows 1256'
                },
                {
                    value: 'windows-1257',
                    label: 'Windows 1257'
                },
                {
                    value: 'windows-1258',
                    label: 'Windows 1258'
                },
                {
                    value: 'x-mac-cyrillic',
                    label: 'X Mac Cyrillic'
                },
            ]
        },
        mediaLibraryUniqueId() {
            const mediaLibraries = this.$store.state.MEDIA_LIBRARY.mediaLibraries;

            if (Object.prototype.hasOwnProperty.call(mediaLibraries, 'list') && mediaLibraries.list.length > 0) {
                return mediaLibraries.list[0].mediaLibraryUniqueId;
            }

            return null;
        },
        contactFields() {
            return [
                {
                    label: this.$t('personal-information'),
                    options: {
                        salutation: this.$tc('salutation', 1),
                        firstName: this.$tc('first-name', 1),
                        lastName: this.$tc('last-name', 1),
                        birthday: this.$tc('birthday', 1),
                    }
                },
                {
                    label: this.$tc('company', 1),
                    options: {
                        company: this.$tc('company-name', 1),
                        vatId: this.$t('vat-id'),
                    }
                },
                {
                    label: this.$tc('physical-address', 1),
                    options: {
                        street: this.$tc('street', 1),
                        postcode: this.$tc('postcode', 1),
                        city: this.$tc('city', 1),
                        state: this.$tc('state', 1),
                        country: this.$tc('country', 1),
                    }
                },
                {
                    label: this.$t('digital-contact-information'),
                    options: {
                        email: this.$tc('email', 1),
                        phonePrivate: this.$t('phone-private'),
                        phoneBusiness: this.$t('phone-business'),
                        phoneMobile: this.$t('phone-mobile'),
                    }
                }
            ]
        }
    },
    data() {
        return {
            step: 1,
            countries,
            upload: {
                active: false,
                progress: 0
            },
            csv: {
                columnMapping: {},
                data: [],
                errors: [],
                headers: [],
                delimiter: null,
                encoding: 'iso-8859-1',
                preview: 20,
            }
        }
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        close() {
            this.$store.commit('backdrop/hide');
            this.$emit('close');
        },
        onParseCsv() {
            const that = this;

            this.$papa.parse(this.$refs.file.files[0], {
                header: true,
                preview: this.csv.preview,
                delimiter: this.csv.delimiter,
                encoding: this.csv.encoding,
                skipEmptyLines: true,
                complete: function (results) {
                    that.csv.data = results.data;
                    that.csv.errors = results.errors;
                    that.csv.headers = results.meta.fields;
                    that.csv.delimiter = results.meta.delimiter;
                    that.csv.columnMapping = {};
                    that.csv.headers.forEach(header => that.csv.columnMapping[header] = '');
                    that.step = 2;
                }
            });
        },
        onSubmit() {
            this.upload.progress = 0;
            this.upload.active = true;

            const mediaObjectUniqueId = v4uuid();
            const Body = this.$refs.file.files[0];
            const Key = mediaObjectUniqueId;
            const Bucket = process.env.VUE_APP_AWS_BUCKET_NAME + '/' + this.partyUniqueId + '/MEDIA_LIBRARY';
            const queueSize = 1;
            const params = {Key, Body, Bucket, ContentType: 'text/csv; charset=' + this.csv.encoding};
            const managedUpload = new AWS.S3.ManagedUpload({service: S3, params, queueSize});

            managedUpload.on('httpUploadProgress', (e) => {
                this.upload.progress = (e.loaded / e.total * 100).toFixed(2);
            });

            managedUpload.send((error, data) => {
                if (error === null) {
                    const Location = data.Location;
                    const re = /(?:\.([^.]+))?$/;
                    const extension = re.exec(Body.name);

                    this.$store.dispatch('MEDIA_OBJECT/CREATE', {
                        mediaObjectUniqueId,
                        mediaObjectDescShort: Body.name.split('.').slice(0, -1).join('.'),
                        mediaObjectDescLong: null,
                        mediaObjectComment: null,
                        mediaLibraryUniqueId: this.mediaLibraryUniqueId,
                        mediaObjectType: "MEDIA_OBJECT_IMPORT",
                        mediaObjectStatus: "MEDIA_OBJECT_ACTIVE",
                        mediaProperties: {
                            extension: extension[1],
                            sizeInBytes: Body.size.toString(),
                            contentType: Body.type,
                            fullPath: Location
                        },
                    })
                        .then(() => {
                            this.$store.dispatch('PROCESS_JOB/CREATE', {
                                processJobUniqueId: null,
                                processJobDescShort: 'CONTACT_IMPORT',
                                processJobDescLong: null,
                                processJobComment: null,
                                processJobType: 'PROCESS_JOB_IMPORT',
                                processJobStatus: 'PROCESS_JOB_NEW'
                            })

                                .then(processJobResponse => {
                                    const workflowConfig = {
                                        processScheduleStartDt: null,
                                        processScheduleEndDt: null,
                                        processScheduleRepeatUnit: null,
                                        processScheduleRepeatValue: null,
                                        channelUniqueId: null,
                                        steps: [
                                            {
                                                stepUniqueId: v4uuid(),
                                                stepDescShort: 'Contact Import',
                                                stepType: 'IMPORT',
                                                stepParentUniqueId: '',
                                                stepConditions: {},
                                                stepConfig: {
                                                    mediaObjectUniqueId,
                                                    processJobUniqueId: processJobResponse.processJobUniqueId,
                                                    object: 'CONTACT',
                                                    contentType: 'CSV',
                                                    delimiter: this.csv.delimiter,
                                                    encoding: this.csv.encoding,
                                                    headings: true,
                                                    columnMapping: this.csv.columnMapping
                                                }
                                            }
                                        ]
                                    }

                                    this.$store.dispatch('WORKFLOW/CREATE', {
                                        workflowUniqueId: null,
                                        workflowDescShort: 'CONTACT_IMPORT_CSV_' + moment().format('YYYYMMDDHHmmss'),
                                        workflowDescLong: 'File: ' + Body.name,
                                        workflowComment: null,
                                        workflowType: 'WORKFLOW_ONE_TIME',
                                        workflowStatus: 'WORKFLOW_ACTIVE',
                                        workflowConfig,
                                    })
                                        .then(() => {
                                            this.upload.active = false;
                                            this.upload.progress = 0;
                                            this.$store.dispatch('toast/success', {
                                                title: this.$t('toast.success.success'),
                                                message: this.$t('toast.success.import-contacts')
                                            });
                                            this.$router.push({name: 'automation-workflows'});
                                        })
                                        .catch(error => {
                                            this.$store.dispatch('toast/danger', {
                                                title: this.$t('toast.danger.error'),
                                                message: error.response.data.message
                                            });
                                        })
                                })
                        })
                        .catch(error => {
                            this.$store.dispatch('toast/danger', {
                                title: this.$t('toast.danger.error'),
                                message: error.response.data.message
                            });
                        })
                        .catch(error => {
                            S3.deleteObject({Bucket, Key}, (error) => {
                                if (error) {
                                    console.error(error);
                                }
                            });
                            this.$store.dispatch('toast/danger', {
                                title: this.$tc('toast.danger.file-upload'),
                                message: error.response.data.message
                            });
                        });

                } else {
                    this.$store.dispatch('toast/danger', {
                        title: this.$t('toast.danger.error'),
                        message: this.$tc('toast.danger.file-upload')
                    });
                }
            });
        },
        isContactFieldDisabled(value) {
            return Object.values(this.csv.columnMapping).indexOf(value) !== -1;
        }
    },
    beforeMount() {
        this.$store.dispatch('MEDIA_LIBRARY/LIST', {
            filter: {},
            order: {meta_insert_dt: 'DESC'},
            page: 1,
            itemsPerPage: 25,
            limit: null,
        });
    }
}
</script>

<style lang="scss" scoped>
.upload-overlay {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .6);

    .progress {
        width: 100%;
        height: 2rem;
    }
}
</style>
